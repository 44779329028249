import React, { useEffect, useRef } from 'react'
import styled from 'styled-components'
import { RunningLine } from 'components/RunningLine'

import { Reviews } from 'blocks/Reviews'

import { HelmetFunc } from 'components/PageMetaData'

import { displayWidth } from 'styles/width'

import { useTranslation } from 'react-i18next'
import { Header } from 'blocks/Header/Header'
import { PromoHeroMobile3d } from 'blocks/Heros/PromoHeroMobile3d'
import { AdvantagesServices } from 'blocks/AdvantagesService'
import { SelectionOfPaintsPosadka } from 'blocks/SelectionOfPaintPosadka'
import { PromoHeroColor } from 'blocks/Heros/PromoHeroColor'
import { graphql } from 'gatsby'
import { sendEvent, gtag } from 'tracking'
import { DefaultFormBlock } from 'blocks/DefaultFormBlock'
import { usePagePath } from 'hooks/usePagePath'
import { MobileMenuPosadka } from 'components/MobileMenuPosadka'
import { PosadkaLinkBlock } from 'components/PosadkaLinkBlock'

const Desktop = styled.div`
    position: relative;
    display: none;
    width: 100%;
    height: 100vh;
    @media (min-width: ${displayWidth.tablet}) {
        display: block;
    }
`
const Wrap = styled.div`
    top: 66px;
    height: calc(100vh - 126px);
    left: 0;
    right: 0;
    bottom: 60px;
    position: absolute;
    overflow: auto;
    @media (min-width: ${displayWidth.tablet}) {
        display: none;
    }
`
const WrapDesktop = styled.div`
    display: none;
    @media (min-width: ${displayWidth.tablet}) {
        display: block;
        top: 0;
        height: calc(100vh - 80px);
        margin-top: 80px;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        overflow: auto;
    }
`

const pageMetadata = {
    uk: {
        title: 'Підбір кольорів і текстур в дизайні інтер`єру',
        description:
            "Дизайнер підбере кольори і текстури для вашого інтер'єру на консультації в офісі",
    },
    ru: {
        title: 'Подбор цветов и текстур в дизайне интерьера',
        description:
            'Дизайнер подберет цвета и текстуры для вашего интерьера на консультации в офисе',
    },
    en: {
        title: 'Selection of colors and textures in interior design',
        description:
            'The designer will select colors and textures for your interior for consultation in the office',
    },
}

/* eslint-disable @typescript-eslint/no-explicit-any */
const PosadkaColor = ({ data }: { data: any }) => {
    const { t } = useTranslation()

    const { i18n } = useTranslation()

    const { getPagePath } = usePagePath()
    const scrolled25Send = useRef(false)
    const scrolled50Send = useRef(false)
    const scrolled75Send = useRef(false)
    const scrolled100Send = useRef(false)

    const pagePath = getPagePath(i18n.language)

    // Reset scroll event when page changes
    useEffect(() => {
        scrolled25Send.current = false
        scrolled50Send.current = false
        scrolled75Send.current = false
        scrolled100Send.current = false
        gtag('config', `${process.env.GA_ID}`, {
            // eslint-disable-next-line camelcase
            page_location: document.location,
        })
    }, [pagePath])

    const onScroll = () => {
        const block = document.getElementById('blockColor')
        const scrollPosition = block!.scrollTop
        const windowHeight = block!.clientHeight
        const bodyHeight = block!.scrollHeight
        const blockMod = document.getElementById('wrap')
        const scrollPositionMob = blockMod!.scrollTop
        const windowHeightMob = blockMod!.clientHeight
        const bodyHeightMob = blockMod!.scrollHeight
        setTimeout(() => {
            const trackScroll = () => {
                const scrolledRation = Math.ceil(
                    ((scrollPosition + windowHeight) / bodyHeight) * 100
                )
                if (
                    block &&
                    !scrolled100Send!.current &&
                    scrolledRation >= 100
                ) {
                    sendEvent('100', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled100Send!.current = true
                    return
                }

                if (block && !scrolled75Send!.current && scrolledRation >= 75) {
                    sendEvent('75', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled75Send!.current = true
                    return
                }

                if (block && !scrolled50Send!.current && scrolledRation >= 50) {
                    sendEvent('50', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled50Send!.current = true
                    return
                }

                if (block && !scrolled25Send!.current && scrolledRation >= 25) {
                    sendEvent('25', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled25Send!.current = true
                }
            }
            const trackScrollMob = () => {
                const scrolledRationMob = Math.ceil(
                    ((scrollPositionMob + windowHeightMob) / bodyHeightMob) *
                        100
                )
                if (
                    block &&
                    !scrolled100Send!.current &&
                    scrolledRationMob >= 100
                ) {
                    sendEvent('100', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled100Send!.current = true
                    return
                }

                if (
                    block &&
                    !scrolled75Send!.current &&
                    scrolledRationMob >= 75
                ) {
                    sendEvent('75', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled75Send!.current = true
                    return
                }

                if (
                    block &&
                    !scrolled50Send!.current &&
                    scrolledRationMob >= 50
                ) {
                    sendEvent('50', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled50Send!.current = true
                    return
                }

                if (
                    block &&
                    !scrolled25Send!.current &&
                    scrolledRationMob >= 25
                ) {
                    sendEvent('25', {
                        eventCategory: 'ScrollDepth',
                    })
                    scrolled25Send!.current = true
                }
            }
            trackScrollMob()
            trackScroll()
        }, 700)
    }
    return (
        <div>
            <HelmetFunc data={pageMetadata} />
            <Wrap id="wrap" onScroll={onScroll}>
                <MobileMenuPosadka />
                <PosadkaLinkBlock />
                <SelectionOfPaintsPosadka imagesData={data.allImageSharp} />
                <AdvantagesServices imagesData={data.allImageSharp} imgNot />

                <RunningLine inverse>{t('designProject99')}</RunningLine>

                <PromoHeroMobile3d />
            </Wrap>

            <Desktop id="blockColor" onScroll={onScroll}>
                <Header />
                <WrapDesktop>
                    <PromoHeroColor imagesData={data.allImageSharp} />
                    <RunningLine inverse>{t('designProject99')}</RunningLine>
                    <SelectionOfPaintsPosadka imagesData={data.allImageSharp} />
                    <div id="selectionOfPaintPosadka" />
                    <AdvantagesServices imagesData={data.allImageSharp} />
                    <Reviews />
                    <RunningLine>{t('designProject99')}</RunningLine>
                    <DefaultFormBlock
                        textTitle
                        withPhoneMobile
                        tracking={{
                            conversionType: 'PosadkaColor',
                            eventCategory: 'Bottomform',
                        }}
                    />
                </WrapDesktop>
            </Desktop>
        </div>
    )
}

export default PosadkaColor

export const query = graphql`
    query {
        allImageSharp {
            edges {
                node {
                    fluid(srcSetBreakpoints: [800]) {
                        originalName
                        ...GatsbyImageSharpFluid
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
        allFaqColorYaml {
            edges {
                node {
                    title
                    image
                    subTitle
                    buttonTextOpen
                    buttonTextClose
                    questions {
                        question
                        answer
                    }
                    parent {
                        ... on File {
                            name
                        }
                    }
                }
            }
        }
    }
`
